// 问卷列表
const getTopicListURL = `/activityApi/api/qu/topicList`;
// 查询话题详情
const getTopicInfoByIdURL = `/accApi/accPoints/topic/getTopicInfoById`;
// 点赞
const addPraiseURL = `/accApi/accPoints/topic/addPraise`;
// 取消点赞
const cancelPraiseURL = `/accApi/accPoints/topic/cancelPraise`;
// 进行评论
const addCommentURL = "/accApi/accPoints/topic/addComment";
// 话题评论列表分页查询
const getTopicCommentList = "/accApi/accPoints/topic/getTopicCommentList";
// 评论回复列表分页查询
const getTopicReplyList = "accApi/accPoints/topic/getTopicReplyList";

// 判断用户是否申请过 “和” 伙人
const getUserTalentStatusURL = `/accApi/accPoints/talent/getUserTalentStatus`;

export {
  getTopicListURL,
  getTopicInfoByIdURL,
  addPraiseURL,
  cancelPraiseURL,
  addCommentURL,
  getTopicCommentList,
  getTopicReplyList,
  getUserTalentStatusURL,
};
