<template>
  <div class="page">
    <v-refresh v-model="isLoading" @refresh="onRefresh">
      <header class="header">
        <img src="./img/header_bg.png" alt="图片加载失败..." />
      </header>
      <div class="main">
        <div class="nav">
          <div
            v-for="item in navList"
            :key="item.key"
            :class="{ 'nav-index': navKey === item.key }"
            @click="setNavKey(item.key)"
          >
            <span>{{ item.label }}</span>
            <div class="line"></div>
          </div>
        </div>
        <v-list
          :finished="finished"
          @onLoad="onLoad"
          ref="list"
          :finishedText="finishedText"
        >
          <div
            class="list-content"
            v-for="(item, index) in listData"
            :key="index"
            @click="toRouteTitle(item)"
          >
            <div class="list-sidebar">
              <!-- <img :src="textMate(item, 'iconPath')" /> -->
              <img :src="item.imageUrls" alt="" />
            </div>
            <div class="list-wrapper">
              <div class="left-wrap_top">
                <div class="left-title">
                  <span>{{ item.title || "" }}</span>
                </div>
                <div
                  class="left-btn"
                  :style="textMate(item, 'style')"
                  v-if="textMate(item, 'btnText')"
                >
                  <span>{{ textMate(item, "btnText") }}</span>
                </div>
              </div>
              <div class="left-wrap_bottom">
                <div
                  class="iconTag"
                  v-if="item.quStatus"
                  :style="{
                    background: textMap[item.quStatus][1],
                    color: textMap[item.quStatus][2],
                    borderColor: textMap[item.quStatus][3],
                  }"
                >
                  <span>{{ textMap[item.quStatus][0] }}</span>
                </div>
                <div class="endTime">
                  <span>结束时间：</span>
                  <span>{{ item.endDate }}</span>
                </div>
              </div>
            </div>
          </div>
        </v-list>
      </div>
    </v-refresh>
  </div>
</template>

<script>
import { mapState } from "vuex";
import wx from "weixin-js-sdk";
import { getTopicListURL } from "./api.js";
import { gloabalCount } from "@/utils/common";
// import { vComments } from "@/components/control/index";

export default {
  name: "questionnaireList",
  // components: {
  //   vComments,
  // },
  data() {
    return {
      navKey: "1",
      navList: [
        {
          label: "小和问卷",
          key: "1",
        },
        {
          label: "小和投票",
          key: "2",
        },
      ],
      isShowComments: false,
      textMap: {
        // 问卷状态
        1: ["未开始", "#EFEFEF", "#AAAAAA", "#D1D1D1"],
        2: ["进行中", "#FFF3EB", "#FE6F16", "#FFC7A4"],
        3: ["已结束", "#EFEFEF", "#AAAAAA", "#D1D1D1"],
      },
      hasAnswerMap: {
        // 是否回答
        1: "是",
        2: "否",
      },
      isLoading: false,
      finished: false, // 列表是否记载完成
      finishedText: "没有更多了", // 列表加载完成后的提示语
      listData: [], // 列表数据集合
      curData: null,
      curPage: 1,
      pageSize: 20,
    };
  },
  computed: {
    ...mapState(["userId", "tenantId", "communityId", "houseId"]),
  },
  created() {},
  mounted() {
    gloabalCount("", 27, 1);
  },
  methods: {
    setNavKey(val) {
      if (val === this.navKey) {
        return;
      }
      if (val == 2) {
        gloabalCount("", 29, 1);
      }
      if (val == 1) {
        gloabalCount("", 27, 1);
      }
      this.navKey = val;
      this.onRefresh();
    },
    // 加载列表数据
    onLoad() {
      this.$axios
        .get(getTopicListURL, {
          params: {
            // communityId: this.communityId,
            userId: this.userId,
            // quStatus: 2, // 状态：1:未开始;2:进行中;3:已结束
            shelfSts: 1, // 0 待发布 1 已发布 2 已下架
            curPage: this.curPage,
            pageSize: this.pageSize,
            quType: this.navKey,
          },
        })
        .then((res) => {
          if (this.curPage >= res.data.pages) {
            this.finished = true;
          } else {
            this.finished = false;
            this.$refs.list.loading = false;
          }
          this.finishedText = "没有更多了";
          if (res.data.records.length === 0) {
            this.listData = [];
            this.finishedText = "";
            return;
          }
          if (res.code === 200 && res.data && res.data.records) {
            if (this.curPage === 1) {
              this.listData = res.data.records;
            } else {
              const list = this.listData.map((v) => v.id);
              res.data.records.forEach((item) => {
                // 如果请求数据不重复，则添加进listData
                list.includes(item.id) || this.listData.push(item);
              });
            }
            this.curPage++;
          }
        })
        .catch(() => {
          this.finished = true;
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    onRefresh() {
      this.curPage = 1;
      this.onLoad();
    },
    textMate(item, str) {
      const { quStatus, hasAnswer } = item;
      const result = {
        btnText: ["填写问卷", "已填写", "查看结果", "投票", "已投票"],
        iconPath: [
          "./img/icon_padding.png",
          "./img/icon_compele.png",
          "./img/icon_over.png",
          "./img/icon_padding.png",
          "./img/icon_compele.png",
        ],
        style: [
          { background: "#FE6F16 ", color: "#FFFFFF" },
          { color: "#fe6f16", borderColor: "#fe6f16" },
          { color: "#fe6f16", borderColor: "#fe6f16" },
          { background: "#FE6F16 ", color: "#FFFFFF" },
          { color: "#fe6f16", borderColor: "#fe6f16" },
        ],
      };
      if (quStatus == 2 && hasAnswer == 2) {
        // 进行中且未回答
        if (this.navKey == 2) {
          return result[str][3];
        } else {
          return result[str][0];
        }
      } else if (quStatus == 2 && hasAnswer == 1) {
        // 进行中但已回答
        if (this.navKey == 2) {
          return result[str][4];
        } else {
          return result[str][1];
        }
      } else if (quStatus == 3) {
        // 已结束
        return result[str][2];
      }
    },
    // 点击跳转话题详情页
    toRouteTitle(item) {
      if (item.quStatus === 1) {
        return;
      }
      // quStatus 状态：1:未开始;2:进行中;3:已结束
      let params = {
        id: item.id,
        // isView: item.quStatus !== 2 || item.hasAnswer === 1, // 非进行中的仅有查看权限, 1 已参与 2 未参与  已参与人也仅有查看权限
        hasAnswer: item.hasAnswer,
      };
      if (this.navKey == 1) {
        if (wx.miniProgram) {
          wx.miniProgram.navigateTo({
            url: `/xubPages/questionnaireDetail/index?params=${JSON.stringify(
              params
            )}`,
          });
        }
      } else {
        if (wx.miniProgram) {
          wx.miniProgram.navigateTo({
            url: `/xubPages/voteDetail/index?params=${JSON.stringify(params)}`,
          });
        }
      }
    },
  },
};
</script>

<style lang="less" scoped>
.page {
  // background: #f5f5f5;
  // min-height: 100vh;
  box-sizing: border-box;
  padding-bottom: constant(safe-area-inset-bottom); /* 兼容 iOS < 11.2 */
  padding-bottom: env(safe-area-inset-bottom); /* 兼容 iOS >= 11.2 */
  ::v-deep .van-pull-refresh {
    min-height: 100vh;
  }
  header.header {
    width: 100%;
    height: 324px;
    overflow: hidden;
    z-index: 2;
    > img {
      height: 100%;
      width: 100%;
    }
  }
  .nav {
    box-sizing: border-box;
    height: 96px;
    display: flex;
    justify-content: center;
    padding: 28px 0;
    margin-bottom: 32px;
    box-shadow: 0px 4px 0px 0px #f0f0f0;
    > div {
      width: 200px;
      height: 40px;
      display: flex;
      font-size: 28px;
      justify-content: center;
      align-items: center;
      position: relative;
      span {
        line-height: 1;
      }
      .line {
        display: none;
      }
    }
    .nav-index {
      font-weight: bold;
      .line {
        position: absolute;
        bottom: -30px;
        left: 50%;
        transform: translateX(-50%);
        display: block;
        height: 8px;
        width: 64px;
        background: #007eff;
        border-radius: 4px;
      }
    }
    // 样式一

    // 样式二
    // padding: 32px;
    // font-size: 32px;
    // display: flex;
    // justify-content: flex-end;
    // > div {
    //   margin-left: 16px;
    //   padding: 12px 36px;
    //   border: 2px solid #007eff;
    //   border-radius: 32px;
    //   font-size: 28px;
    //   display: flex;
    //   color: #007eff;
    //   > span {
    //     line-height: 1;
    //     vertical-align: middle;
    //   }
    //   // line-height: 46px;
    // }
    // .nav-index {
    //   background: #007eff;
    //   color: #fff;
    // }
  }
  .main {
    box-sizing: border-box;
    margin: 0px 0 0 0;
    .list-content {
      border-radius: 16px;
      background: #ffffff;
      display: flex;
      overflow: hidden;
      margin: 32px 32px 0px 32px;
      box-shadow: 0px 0px 32px 0px rgba(66, 114, 153, 0.1);
      box-sizing: border-box;
      padding: 32px;
      .list-sidebar {
        width: 96px;
        min-width: 96px;
        height: 96px;
        border-radius: 50%;
        overflow: hidden;
        > img {
          width: 100%;
          height: 100%;
        }
      }
      .list-wrapper {
        flex: 1;
        box-sizing: border-box;
        max-width: calc(100% - 96px);
        padding-left: 16px;
        .left-wrap_top {
          display: flex;
          align-items: center;
          .left-title {
            flex: 1;
            min-height: 88px;
            font-size: 32px;
            font-weight: bold;
            color: #1a1c34;
            white-space: normal;
            word-break: break-all;
            span {
              display: inline-block;
              line-height: 45px;
            }
          }
          .left-btn {
            box-sizing: border-box;
            height: 64px;
            border: 4px solid #fe6f16;
            border-radius: 32px;
            color: #fe6f16;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 0 24px;
            min-width: 168px;
            > span {
              font-size: 28px;
              font-weight: bold;
              line-height: 1;
            }
          }
        }
        .left-wrap_bottom {
          display: flex;
          align-items: center;
          white-space: nowrap;
          margin-top: 36px;
          .iconTag {
            box-sizing: border-box;
            max-width: calc(100% - 304px);
            display: flex;
            height: 40px;
            align-items: center;
            border: 2px solid #ffc7a4;
            border-radius: 8px;
            background: #fff3eb;
            color: #fe6f16;
            margin-right: 22px;
            > span {
              box-sizing: border-box;
              line-height: 1;
              font-size: 24px;
              padding: 0px 8px;
              text-overflow: ellipsis;
              overflow: hidden;
            }
          }
          .endTime {
            flex: 1;
            display: flex;
            align-items: center;
            font-size: 28px;
            font-weight: 400;
            color: #666666;
            height: 40px;
            span {
              line-height: 1;
            }
          }
        }
      }
    }
  }
}
</style>
